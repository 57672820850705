
.site__footer {
  background-color: $gray-lighter;
  padding: 2em 0;
}

.site__footer__container {
  padding-left:  15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
    padding-left:  0;
    padding-right: 0;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

.site__footer__left {
  display: none;
}

.site__footer__right_content {
  display: flex;
  justify-content: space-between;
}

@media (min-width: $screen-sm-min) {
  .site__footer__container {
    display: flex;
    justify-content: space-between;
  }
  .site__footer__left {
    display: block;
    width: 45%;
  }
  .site__footer__right {
    width: 40%;
  }
}

.site__footer__copyright {
  text-align: center;
  margin-top: 1em;
  color: $gray-light;
  @media (min-width: $screen-xs-min) {
    text-align: right;
  }
}

.site__footer__menu {
  flex: 1;
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      padding: 0;
      margin: 1px 0;
    }
    a {
      display: block;
      padding: 4px 0;
      @media (min-width: $screen-xs-min) {
        padding: 0;
      }
    }
  }
}

.site__footer__social {
  a {
    display: inline-block;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    margin-left: 1em;
    @media (min-width: $screen-xs-min) {
      margin: 0;
    }
  }
}

body {
  min-height: 100vh;
}

.container {
  @include container-fixed;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

.site__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100vh;
}

.site__header {
  flex-grow: 0;
  flex-shrink: 0;
}

.site__content {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 1em 0 2em 0;
}

.site_footer {
  flex-grow: 0;
  flex-shrink: 0;
}

.store {
  @media (min-width: $screen-sm-min) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

.store__sidebar {
  @media (min-width: $screen-sm-min) {
    width: 35%;
  }
  @media (min-width: $screen-md-min) {
    width: 25%;
  }
}

.widget {
  .product-menu__categories {
    margin: 0 0 0 1em;
    padding: 0;
  }
  .product-menu__category {
    margin: 0 0 1.5em 0;
    padding:0;
  }
  .product-menu__category__name {
    display: block;
    font-size: 1.2em;
    border-bottom: solid 1px #ccc;
    color: $text-color;
  }
  .product-menu__products {
    margin: 1em 0 0 0;
    padding: 0 0 0 1em;
    list-style: none;
  }
  .product-menu__product {
    margin: 0;
    padding: 0;
    a {
      display: block;
      color: $text-color;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.store__content {
  @media (min-width: $screen-sm-min) {
    padding-left: 30px;
    width: 65%;
  }
  @media (min-width: $screen-md-min) {
    width: 75%;
  }
}

.product--single__top {
  margin-bottom: 2em;
  @media (min-width: $screen-md-min) {
    display: flex;
    justify-content: space-between;

  }
}

.product--single__left {
  @media (min-width: $screen-md-min) {
    width: 35%;
  }
}

.product--single__right {
  @media (min-width: $screen-md-min) {
    padding-left: 30px;
    width: 65%;
  }
}

.product--single__order {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}

.product__price {
  font-size: 24px;
  line-height: 2;
  .price {
    margin: 0;

  }
}

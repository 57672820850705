.button {
  outline: none;
  &:focus {
    outline: inherit;
  }
}

.button.button-default {
  color: $text-color;
  background-color: #fff;
  border: none;
  box-shadow: none;
  &:hover,&:active,&:focus,&:visited {
    color: inherit;
    background-color: inherit;
    border: inherit;
    box-shadow: inherit;
    outline: inherit;
  }
}
.button.button-success {
  color: $btn-primary-color;
  background-color: $btn-primary-bg;
  border-color: $btn-primary-border;
  &:hover {
    background-color: darken($btn-primary-bg, 10%);
    border-color: darken($btn-primary-border, 12%);
  }
}
.button.button-success {
  color: $btn-success-color;
  background-color: $btn-success-bg;
  border-color: $btn-success-border;
  &:hover {
    background-color: darken($btn-success-bg, 10%);
    border-color: darken($btn-success-border, 12%);
  }
}
.button.button-info {
  color: $btn-info-color;
  background-color: $btn-info-bg;
  border-color: $btn-info-border;
  &:hover {
    background-color: darken($btn-info-bg, 10%);
    border-color: darken($btn-info-border, 12%);
  }
}
.button.button-warning {
  color: $btn-warning-color;
  background-color: $btn-warning-bg;
  border-color: $btn-warning-border;
  &:hover {
    background-color: darken($btn-warning-bg, 10%);
    border-color: darken($btn-warning-border, 12%);
  }
}
.button.button-danger {
  color: $btn-danger-color;
  background-color: $btn-danger-bg;
  border-color: $btn-danger-border;
  &:hover {
    background-color: darken($btn-danger-bg, 10%);
    border-color: darken($btn-danger-border, 12%);
  }
}

.link-social {
  display: inline-block;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

@mixin link-social($color-bg, $color: #fff) {
  color: $color-bg;
  &:hover {
    color: darken($color-bg, 10%);
  }
}

.button-facebook {
  @include link-social($link-social-facebook);
}
.button-twitter {
  @include link-social($link-social-twitter);
}
.button-google {
  @include link-social($link-social-google);
}
.button-linkedin {
  @include link-social($link-social-linkedin);
}
.button-yelp {
  @include link-social($link-social-yelp);
}
.button-youtube {
  @include link-social($link-social-youtube);
}
.button-pinterest {
  @include link-social($link-social-pinterest);
}


.blogwrapper {
  @media (min-width: $screen-md-min) {
    display: flex;
    justify-content: space-between;
  }
}

.blog__content {
  @media (min-width: $screen-md-min) {
    padding-right: 30px;
  }
}

.blog__sidebar {
  //width: 33%;
  @media (min-width: $screen-md-min) {
    width: 25%;

  }
}

.post__header {
  margin-bottom: 2em;
}

.post__date {
  font-size: 18px;
  font-weight: bold;
}

.post__content {
  margin-top: 1em;
}

.post__summary {
  margin-top: 1em;
}

.post--teaser {
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid $gray-lighter;
}

.post__comments {
  margin-top: 2em;
  border-top: 1px solid $gray-lighter;
}

.widget .widget-title, .widget .widgettitle {
  border-bottom: none;
  padding: 0;
  margin: 0;
}


img {
  max-width: 100%;
  height: auto;
}

a {
  color: $link-color;
  text-decoration: none;
  font-weight: normal;
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    text-decoration: none;
    outline: none;
  }
}

.post__sharing {
  iframe {
    width: auto;
    margin: 0;
  }
}

.fb_iframe_widget {
  top: -3px;
}

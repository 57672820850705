.features {
  margin: 2em 0;
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: $screen-sm-min) {
    flex-direction: row;
    align-items: stretch;
  }
}

.feature {
  min-height: 200px;
  padding: 2em 3em;
  margin-bottom: 2em;
  background-image: url('#{$img-dir}/chalkboard-background.jpg');
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 75%;
  @media (min-width: $screen-sm-min) {
    width: 30%;
    min-height: 300px;
  }
}

.feature__title {
  color: #fff;
  text-align: center;
  margin: 0 0 1em 0;
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 24px;
}

.feature__content {
  flex-grow: 1;
  flex-shrink: 0;
}

.feature__cta {
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 1em;
  a {
    width: 120px;
  }
}

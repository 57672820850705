

.site__header {
  padding: 60px 0;
  background-image: url('#{$img-dir}/chalkboard-background.jpg');
  background-size: cover;
  background-position: left bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    background-color: #fff;
  }
  .site__header_content {
    @media (min-width: $screen-md-min) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.home {
  .site__header {
    background-image: url('#{$img-dir}/schoolhouse.jpg');
    @media (min-width: $screen-sm-min) {
      min-height: 400px;
      align-items: flex-start;
    }
    @media (min-width: $screen-md-min) {
      min-height: 600px;
    }
  }
}

.site__header_logo {
  text-align: center;
  @media (min-width: $screen-md-min) {
    text-align: left;
  }
  a {
    display: block;
  }
}

.site__header__menu__toggle {
  text-align: right;
  margin-bottom: 1em;
  .btn-default {
    &:active, &:hover, &:focus {
      background-color: $btn-default-bg;
      color: $btn-default-color;
      border-color: $btn-default-border;
      box-shadow: none;
    }
  }
  @media (min-width: $screen-md-min) {
    display: none;
  }
}

.site__header__menu {
  display: none;
  margin:0;
  padding:0;
  li {
    display: block;
    margin:0;
    padding:0;
    a {
      display: block;
      margin:0;
      padding: 10px 8px;
      color: $text-color;
      text-decoration: none;
      font-size: 16px;
      border-top: solid 1px $gray-lighter;
      &:hover {
        color: $text-color;
      }
      @media (min-width: $screen-md-min) {
        border: none;
        padding: 8px 18px;
        font-weight: bold;
      }
    }
  }
  @media (max-width: $screen-md) {
    &.open {
      display: block;
    }
  }
  @media (min-width: $screen-md-min) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
}



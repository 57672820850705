.register--home {
  width: 75%;
  margin: 1em auto;
  @media (min-width: $screen-sm-min) {
    width: 100%;
    //margin: 1em -15px;
    //display: flex;
  }
}

.register--home {
  @include clearfix;
}

.register__image {
  display: none;
  @media (min-width: $screen-sm-min) {
    width: 33%;
    float: left;
    display: inline-block;
  }
}

.register__content {
  @media (min-width: $screen-sm-min) {
    float: right;
    width: 60%;
  }
}

.register__content_title {
  font-size: 24px;
}

.register__content_cta {
  margin-top: 2em;
  .button {
    padding-left: 25px;
    padding-right: 25px;
  }
}
